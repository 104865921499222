<template>
  <v-row class="ma-4" dense align="center">
    <v-col cols="12" class="header mb-2 pl-2 py-4">
      <span style="font-size: 24px; font-weight: 400">{{
        $t("กรอกข้อมูล document")
      }}</span>
      <!-- <v-btn style="float: right" color="grey" dark @click="savedraft()">
        <v-icon class="mr-1">mdi-file-edit</v-icon>save draft</v-btn
      > -->
    </v-col>
    <v-card outlined width="100vw" class="pa-4">
      <v-row class="mt-2">
        <v-col cols="6" md="2" class="mt-2">
          <span>{{ $t("Document Type") }}</span>
        </v-col>
        <v-col col="12" md="4" class="ml-2">
          <v-text-field v-model="form.document_type" solo dense></v-text-field>
        </v-col>
      </v-row>

      <v-row class="mt-2">
        <v-col cols="6" md="2" class="mt-2">
          <span>{{ $t("Description") }}</span>
        </v-col>
        <v-col col="12" md="4" class="ml-2">
          <v-text-field v-model="form.description" solo dense></v-text-field>
        </v-col>
      </v-row>

      <v-row class="pa-9" justify="end">
        <v-btn color="#ff78ac " dark @click="submit()"> บันทึก </v-btn>
        <v-btn @click="cancel()">ยกเลิก</v-btn>
      </v-row>
    </v-card>
  </v-row>
</template>

<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      findInfo: [],
      getOneItemAffiliation: [],
      itemChangeLang: "",
      userData: [],
      EngLang: true,
      nationalityItems: [],
      itemsCountry: [],
      itemsCity: [],
      itemsroyal_thai_embassy: [],
      itemsstudent_types: [],
      Sub_mStudentType: [],
      itemsexchange_visiting_student: [],
      itemsaffiliation_faculty: [],
      itemslevel_Of_education: [],
      itemsdepartment: [],
      itemscovid19_vaccine: [],

      itemsTitle: [
        {
          textTH: "นาย",
          textEN: "Mr.",
          value: "Mr",
        },
        {
          textTH: "นางสาว",
          textEN: "Ms.",
          value: "Ms",
        },
        // {
        //   textTH: "นาง",
        //   textEN: "Mrs.",
        //   value: "Mrs",
        // },
      ],
      itemscvv_name: [
        {
          textTH: "ไฟเซอร์",
          textEN: "Pfizer",
          value: "Pfizer",
        },
        {
          textTH: "โมเดอร์นา",
          textEN: "Moderna",
          value: "Moderna",
        },
      ],
      // vaccinedate1Menu: false,
      // vaccinedate2Menu: false,
      // vaccinedate3Menu: false,
      // vaccinedate4Menu: false,
      // vaccinedate5Menu: false,
      // date_of_birthMenu: false,
      // edu_infor_startMenu: false,
      // edu_infor_endMenu: false,
      // visa_expiry_dateMenu: false,
      // visa_extension_untilMenu: false,

      // dateRecoveryMenu1: false,
      // dateRecoveryMenu2: false,
      // dateRecoveryMenu3: false,
      // selectedcountry: null,
      // selectedcity: null,
      form: {
        attachFile1: "",
        attachFile2: "",
        attachFile3: "",
        attachFile4: "",
        attachFile5: "",
        attachFile6: "",
        attachFile7: "",
        internalMemoFile: "",

        mCountryId: null,
        mCityId: null,
        mAddressId: null,
        showname: "",
        title: "",
        give_name: "",
        middle_name: "",
        family_name: "",
        mNationalityId: null,
        date_of_birth: null,
        email: "",
        passport_number: "",
        is_medical_certificate_vaccine_covid: null,

        recovery1year: null,
        date_of_first_positive: null,
        certification_valid_form: null,
        certification_valid_until: null,
        vaccinedate1: null,
        vaccinedate2: null,
        vaccinedate3: null,
        vaccinedate4: null,
        vaccinedate5: null,
        boostershot: [],
        // "mAddressId": null,
        //   "mCovid19VaccineId": null,
        //   "mBosterApplicationId": null,
        //   "mStudentTypeId": null,
        //   "mExchangeVisitingStudentId": null,
        //   "mAffiliationFacultyId": null,
        //   "mLevelOfEducationId": null,
        //   "mDepartmentId": null,
        //   "mSupportingDocumentId": null
        mStudentTypeId: null,
        mLevelOfEducationId: null,
        mAffiliationFacultyId: null,
        mExchangeVisitingStudentId: null,
        mDepartmentId: null,
        edu_infor_start: null,
        edu_infor_end: null,
        email_cc: "",
        auth_signa_doc_no: "",
        auth_signa_link_dean_director: "",
        auth_signa_operation_full_name: "",
        auth_signa_position: "",
        auth_signa_for: "",
        auth_signa_file_upload: "",
        coor_con_link: "",
        coor_con_full_name: "",
        coor_con_tel: "",
        coor_con_email: "",

        appointment_acting: 0,
        appointment_acting_name: "",
        appointment_acting_position: "",
        appointment_acting_for: "",
      },
      bostername: "",
      bosterdate: "",
      e1: 1,
      rules: {
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        selectRules: [(val) => !!val || "This field is required"],
      },
    };
  },
  watch: {
    "form.mAddressId"(val) {
      console.log("form.mAddressId", val);
    },
  },
  created() {
    const lang = localStorage.getItem("lang");
    if (lang == "en") {
      this.EngLang = true;
      this.itemChangeLang = "name_en";
    } else if (lang == "th") {
      this.EngLang = false;
      this.itemChangeLang = "name_th";
    }

    // this.getEmbassy();
    // this.getStudentTypes();
    // this.getExchangeVisitingStudent();
    // // this.getLevelOfEducation();
    // // this.getDepartment();
    // this.getCovid19Vaccine();
    // this.getCountry();
    // this.getAllNationality();
    // var user = JSON.parse(Decode.decode(localStorage.getItem("userBiAdmin")));

    this.userData = JSON.parse(
      Decode.decode(localStorage.getItem("userCUdata"))
    );
    console.log("thisuserData", this.userData);
  },
  methods: {
    onClickFile(val) {
      window.open(val, "_blank");
    },
    async handleFileUpload(questionNo) {
      // this.data[questionNo] = this.$refs[questionNo].files[0];
      // console.log("file", this.data[questionNo]);
    },
    async submitFile(questionNo) {
      console.log("this.$refs[questionNo]", this.$refs[questionNo]);
      if (this.$refs[questionNo]) {
        if (this.$refs[questionNo].files[0]) {
          // this.loading = true;
          let formData = new FormData();
          formData.append("files", this.$refs[questionNo].files[0]);
          formData.append("filename", this.$refs[questionNo].files[0].name);
          formData.append("type", questionNo);
          // formData.append("userId", this.userId);
          console.log("formData", formData);
          const auth = {
            headers: {
              "Content-Type": "multipart/form-data",
              // Authorization: `Bearer` + " " + this.checkuser.token,
            },
          };
          console.log("auth", auth);
          this.uploaded = true;
          const response = await this.axios.post(
            `${process.env.VUE_APP_API}/documents`,
            formData,
            auth
          );
          if (questionNo == "form.attachFile1") {
            this.form.attachFile1 = response.data.data.path;
          }
          if (questionNo == "form.attachFile2") {
            this.form.attachFile2 = response.data.data.path;
          }
          if (questionNo == "form.attachFile3") {
            this.form.attachFile3 = response.data.data.path;
          }
          if (questionNo == "form.attachFile4") {
            this.form.attachFile4 = response.data.data.path;
          }
          if (questionNo == "form.attachFile5") {
            this.form.attachFile5 = response.data.data.path;
          }
          if (questionNo == "form.attachFile6") {
            this.form.attachFile6 = response.data.data.path;
          }
          if (questionNo == "form.attachFile7") {
            this.form.attachFile7 = response.data.data.path;
          }
          if (questionNo == "form.internalMemoFile") {
            this.form.internalMemoFile = response.data.data.path;
          }
          // if (questionNo == "form.") {
          //   this.pic4 = response.data.data.path;
          // }
          // console.log('this.pic1',this.pic1,'this.pic2',this.pic2)
          // .then(function () {
          console.log("SUCCESS!!");
          console.log("response", response);
          // this.uploaded = false;
        }
      }
    },
    checkstep1() {
      if (this.form.mAddressId) {
        this.e1 = 2;
      } else {
        this.$swal.fire({
          icon: "error",
          text: this.$t("status.embassy"),
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async getOneAffiliation(mStudentTypeId) {
      this.getOneItemAffiliation = [];
      this.form.mAffiliationFacultyId = null;
      this.form.mLevelOfEducationId = null;
      this.form.mDepartmentId = null;
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/affiliation_faculty?mStudentTypeId=${mStudentTypeId}`
      );
      this.getOneItemAffiliation = response.data.data;
    },
    async getOneLevelEducation(mAffiliationFacultyId) {
      this.getInfo(mAffiliationFacultyId);
      this.itemslevel_Of_education = [];
      this.form.mLevelOfEducationId = null;
      this.form.mDepartmentId = null;
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/level_Of_education?mAffiliationFacultyId=${mAffiliationFacultyId}`
      );
      this.itemslevel_Of_education = response.data.data;
      this.getOneDepartment(mAffiliationFacultyId);
    },

    async getOneDepartment(mAffiliationFacultyId) {
      this.itemsdepartment = [];
      this.form.mDepartmentId = null;
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/department?mAffiliationFacultyId=${mAffiliationFacultyId}`
      );
      console.log("response department", response.data.data);
      this.itemsdepartment = response.data.data;
    },

    async getInfo(mAffiliationFacultyId) {
      this.findInfo = this.getOneItemAffiliation.find(
        (x) => x.id === parseInt(mAffiliationFacultyId)
      );

      this.itemsChancellor = [];
      this.findInfo.act_instead_agent_th || this.findInfo.act_instead_agent_en
        ? this.itemsChancellor.push({
            id: 1,
            name_th: this.findInfo.act_instead_agent_th,
            name_en: this.findInfo.act_instead_agent_en,
          })
        : "";

      this.findInfo.dean_director_th || this.findInfo.dean_director_en
        ? this.itemsChancellor.push({
            id: 2,
            name_th: this.findInfo.dean_director_th,
            name_en: this.findInfo.dean_director_en,
          })
        : "";
      this.form.auth_signa_link_dean_director = this.itemsChancellor[0].id;
      if (this.EngLang === true) {
        // this.form.auth_signa_link_dean_director =
        //   this.findInfo.dean_director_en;
        this.form.appointment_acting_name = this.findInfo.agent_en;
        this.form.appointment_acting_position = this.findInfo.position_agent_en;
        this.form.appointment_acting_for = this.findInfo.act_instead_agent_en;
      } else {
        // this.form.auth_signa_link_dean_director =
        //   this.findInfo.dean_director_th;
        this.form.appointment_acting_name = this.findInfo.agent_th;
        this.form.appointment_acting_name = this.findInfo.agent_th;
        this.form.appointment_acting_position = this.findInfo.position_agent_th;
        this.form.appointment_acting_for = this.findInfo.act_instead_agent_th;
      }
      if (this.findInfo.is_agent === true) {
        this.form.appointment_acting = 1;
      } else {
        this.form.appointment_acting = 0;
      }
    },

    async getAllNationality() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/nationality`
      );
      this.nationalityItems = response.data.data;
      console.log("nationalityItems", this.nationalityItems);
    },
    async getAllSubStudentType(mStudentTypeId) {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/subCategoryOfStudentType?mStudentTypeId=${mStudentTypeId}`
      );
      console.log("response", response.data.data);
      this.Sub_mStudentType = response.data.data;
    },
    async getCountry() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/country`
      );
      console.log("response country", response.data.data);
      this.itemsCountry = response.data.data;
    },
    async getCity(mCountryId) {
      this.selectedcity = "";
      this.itemsCity = [];
      // this.form.mAddressId = 0;
      // this.form.mCityId = 0;
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/city?mCountryId=${mCountryId}`
      );
      console.log("city", response.data.data);
      this.itemsCity = response.data.data;
    },
    async getEmbassy(mCityId) {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/address?mCityId=${mCityId}`
      );
      console.log("response address", response.data.data);
      this.itemsroyal_thai_embassy = response.data.data;
      console.log(this.itemsroyal_thai_embassy, "itemsroyal_thai_embassy");
      this.form.mAddressId = this.itemsroyal_thai_embassy[0].id;
    },
    async getStudentTypes() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/student_types`
      );
      console.log("response student_types", response.data.data);
      this.itemsstudent_types = response.data.data;
    },

    async getExchangeVisitingStudent() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/exchange_visiting_student`
      );
      console.log("response exchange_visiting_student", response.data.data);
      this.itemsexchange_visiting_student = response.data.data;
    },
    // async getAffiliationFaculty(mAffiliationFacultyId) {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/affiliation_faculty`
    //   );
    //   console.log("response affiliation_faculty", response.data.data);
    //   this.itemsaffiliation_faculty = response.data.data;
    // },

    async getCovid19Vaccine() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/covid19_vaccine`
      );
      console.log("response covid19_vaccine", response.data.data);
      this.itemscovid19_vaccine = response.data.data;
    },
    async savedraft() {
      // if (this.$refs.createForm.validate(true)) {

      this.userData = JSON.parse(
        Decode.decode(localStorage.getItem("userCUdata"))
      );
      console.log("thisuserData", this.userData);
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      this.form.mCityId = this.selectedcity;
      this.form.mCountryId = this.selectedcountry;
      const data = {
        ...this.form,
        userId: this.userData.id,
        status: "SAVE DRAFT",
      };

      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/documents`,
        data,
        // this.form,
        auth
      );
      console.log("createCate", response);
      console.log(response.data.response_status);
      console.log("this.form", this.form);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: this.$t("status.submitSavedraft"),
          showConfirmButton: false,
          timer: 1500,
        });
        // this.$router.push("ManageVoucher");
      } else {
        this.$swal.fire({
          icon: "error",
          text: this.$t("status.submitUnsuccess"),
          showConfirmButton: false,
          timer: 1500,
        });
      }
      // }
    },
    async submit() {
      // if (this.$refs.createForm.validate(true)) {
      this.$swal({
        html: this.$t("status.submitConfirm"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancel",
        confirmButtonText: "Confirm",
        showDenyButton: true,
        denyButtonColor: "grey",
        denyButtonText: `Save Draft`,
        // reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.userData = JSON.parse(
            Decode.decode(localStorage.getItem("userCUdata"))
          );
          console.log("thisuserData", this.userData);
          const auth = {
            headers: {
              Authorization: `documents ${this.userData.token}`,
            },
          };
          this.form.mCityId = this.selectedcity;
          this.form.mCountryId = this.selectedcountry;
          const data = {
            ...this.form,
            userId: this.userData.id,
            status: this.$t("status.dataStatus"),
          };

          const response = await this.axios.post(
            `${process.env.VUE_APP_API}/documents`,
            data,
            // this.form,
            auth
          );
          console.log("createCate", response);
          console.log(response.data.response_status);
          if (response.data.response_status == "SUCCESS") {
            this.$swal.fire({
              icon: "success",
              text: this.$t("status.submitSuccess"),
              showConfirmButton: false,
              timer: 2000,
            });
            this.$router.push("/Doc7");
          } else {
            this.$swal.fire({
              icon: "error",
              text: this.$t("status.submitUnsuccess"),
              showConfirmButton: false,
              timer: 1500,
            });
          }
          // }
        } else if (result.isDenied) {
          this.savedraft();
        }
      });
    },
    addbooster() {
      this.form.boostershot.push({ bostername: "", bosterdate: "" });
      // this.form.boostershot.push(this.bostername);
      // this.form.boostershot.push(this.bosterdate);
    },
    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.header {
  background-color: #de5c8e;
  padding: 10px;
  color: white;
  border-radius: 5px;
}

.v-text-field--outlined >>> fieldset {
  border-radius: 10px;
}
</style>